// CODE FOR DONATE.JS

.show-gifts {
  @include button-secondary($accent);
  margin: auto 10px;
}

.gift {
  border: 1px solid $light-grey;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 5px;

  &.selected {
    border-color: $brandblue;
    box-shadow: 0 0 0 1px #65a6e8;
  }

  img {
    width: 85px;
    height: 85px;
    object-fit: contain;
    margin-right: 15px;
    flex-shrink: 0;
  }

  .gift-title {
    font-weight: 600;
    font-size: 1.3rem;
  }

  h4 {
    font-size: .9rem;
  }

  .gift-price {
    font-size: 1.25rem;
  }

  .buttons {
    margin-left: auto;
    flex-shrink: 0;
  }

  .info {
    @include button-tertiary($grey);
    margin-right: 15px;
    margin-left: 15px;
  }

  .choose {
    @include button-secondary($brandblue);
    &.selected {
      background: $brandblue;
      color: white;
      box-shadow: none;
      border-color: $brandblue;
      &:focus {
        box-shadow: 1px 1px 4px $brandblue, -1px 1px 4px $brandblue;
      }
    }
  }
  @media (max-width: 650px) and (min-width: 531px) {
    flex-wrap: wrap;
    .buttons {
      margin: 15px auto 0;
      flex-grow: 1;
      flex-basis: 100%;
      text-align: center;
    }
    .info {
      margin-left: 0;
    }
    div {
      flex-basis: 70%;
      flex-grow: 0;
      flex-shrink: 1;
    }
  }
  @media (max-width: 530px) {
    display: block;
    text-align: center;
    > * {
      margin-bottom: 10px;
    }
    .info {
      margin-left: 0;
    }
  }
}

// SWAL – MORE INFO
.swal-modal--gift {
  max-width: 700px;

  .swal-button--close {
    color: $brandblue !important;
    background: transparent !important;
  }

  .item-image {
    max-width: 250px;
    width: 100%;
    margin-bottom: 15px;
  }

  .item-description {
    font-size: 18px;
    line-height: 1.3;
    text-align: left;
  }

  .small-print {
    font-size: 15px;
    padding-top: 15px;
  }
}

// CHOOSE PLAN
.select-donation-container .radio-button-group {
  text-align: left; 
}

.bubbles-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 auto;
}


.donate-main .bubble {
  color: $black;
  width: auto;
  min-width: 75px;
  border-radius: 30px;
  font-size: 1.25rem;
  padding: 10px 15px;
  font-weight: 200;
  margin: 5px;
  border: 1px solid #d5d5d5;
  background: #f5f5f5;
  transition: 0.3s ease all;
  text-align: center;
  cursor: pointer;
  label {
    margin: 0;
    cursor: pointer;
  }
  &:hover,
  &:focus {
    background-color: darken(#f5f5f5, 5%);
    box-shadow: inset 0 0 0 1px #d5d5d5;
    outline: 0;
  }
  &:focus {
    box-shadow: 1px 1px 4px $brandblue, -1px 1px 4px $brandblue;
  }
  &.selected {
    background: $brandblue;
    color: white;
    box-shadow: none;
    border-color: $brandblue;
    &:focus {
      box-shadow: 1px 1px 4px $brandblue, -1px 1px 4px $brandblue;
    }
  }





  .custom-donation-input {
    width: 80px;
    width: 4.06em;
    border-radius: 3px;
    display: inline-block;
    margin: 0 0 0 3px;
    padding: 0 3px;
    font-size: 18px;
    border: 1px solid #276db2; 
    background: white;
    &:focus {
      background: white;
    }

    //hide spinner
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  @media (max-width: 750px) {
    min-width: 55px;
    border-radius: 30px;
    font-size: 1rem;
    padding: 10px 15px;
    .custom-donation-input {
      // width: 4.06em;
      font-size: 1rem;
    }
  }
}


// SWAL – MORE INFO
.swal-modal--gift {
  max-width: 700px;

  .swal-button--close {
    color: $brandblue !important;
    background: transparent !important;
  }

  .item-image {
    max-width: 250px;
    width: 100%;
    margin-bottom: 15px;
  }

  .item-description {
    font-size: 18px;
    line-height: 1.3;
    text-align: left;
  }

  .small-print {
    font-size: 15px;
    padding-top: 15px;
  }
}
