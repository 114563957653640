$shrink: 700px;
$bar-height: 55px;

#player {
  box-sizing: border-box;
  position: relative;
  top: 0;
  color: white;
  z-index: 2;
  flex-shrink: 0;
  background: #2b2a2a;
  width: 260px;
  min-height: 100vh;
  .inner-container {
    height: 100vh;
    max-height: 60em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 260px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    padding: 30px;
    overflow: auto;
    background: #2b2a2a;
    > * {
      margin-bottom: 20px;
    }
  }

  .close,
  .bottom-bar {
    display: none;
  }

  i.fas.fa-pause,
  i.fas.fa-play {
    padding-top: 4px;
  }
  .listen-button {
    margin: 0 0 30px 0;
    @include button-primary($brandblue);
    display: block;
    width: 100%;
    font-size: 20px;
    position: relative;
    z-index: 1;
    &:hover {
      transform: scale(1.05);
      background: $brandblue;
      border-color: $brandblue;
    }
    .fas {
      padding: 5px;
      font-size: 17px;
    }
  }

  .song-artist {
    color: rgba(255, 255, 255, 0.7);
    padding: 7px 0 5px;
  }

  .secondary-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    max-width: 100%;
    text-align: left;
    justify-content: center;
  }
  .skip,
  .cog,
  .volume-button {
    @include button-secondary(white);
    display: block;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  .skip {
    margin: 0 auto 5px;
    opacity: 1;
    &:hover {
      transform: scale(1.04);
    }
  }
  .cog {
    margin-right: 5px;
  }

  .controls button {
    margin-right: 5px;
  }

  .volume-slider {
    flex-basis: 100%;
    flex-grow: 1;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .fidelity-indicator {
    color: white;
    opacity: 0.5;
    background: none;
    border: 0;
    outline: 0;
    margin: 0 auto;
    display: block;
  }

  .bottom-links {
    border-top: 1px solid rgba(204, 204, 204, 0.3);
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    a {
      color: $brandblue;
      color: $light-grey;
      padding-top: 10px;
      &:hover {
        color: white;
      }
      &.popup {
        text-transform: uppercase;
        font-size: 14px;
        margin: auto 0;
        opacity: 0.7;
      }
    }
  }
  .logo {
    margin: 0 auto;
    max-height: 150px;
    animation: 1.5s ease-out 0s 1 run;
    display: block;
    @keyframes run {
      0% {
        opacity: 0.3;
        transform: rotate(-40deg);
      }
      100% {
        opacity: 1;
        transform: rotate(0deg);
      }
    }
  }

  .channel-selector {
    border: 2px solid white;
    border-radius: 10px;
    padding: 5%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: border 500ms;
    text-align: left;
    width: 100%;
    cursor: pointer;
    &:hover {
      border: 2px solid $brandblue;
    }
    p:nth-of-type(1) {
      color: #cacaca;
      font-weight: 400;
      font-size: 14px;
      text-transform: uppercase;
    }
    p:nth-of-type(2) {
      padding-top: 5px;
      font-size: 18px;
      font-weight: 400;
      color: white;
    }
    i {
      font-size: 1.2em;
      margin: auto 0;
      color: white;
    }
  }

  .song {
    text-align: center;
    .song-data {
      .song-name {
        font-weight: 200;
        line-height: 1.2em;
        font-size: 22px;
        color: white;
        margin-bottom: 0;
        @media (min-width: $hidePlayer) {
          max-height: 2.5em;
          padding-top: 5px;
          box-sizing: border-box;
          overflow: hidden;
        }
      }
      h3 {
        font-weight: 400;
        font-size: 17px;
      }
      .song-time {
        font-size: 16px;
        color: #a6a6a6;
      }
      .track-progress {
        .rc-slider-handle {
          display: none;
        }
        .rc-slider-rail,
        .rc-slider-track,
        .rc-slider-mark {
          height: 4px;
        }
        .rc-slider-track {
          background-color: $brandblue;
        }
      }
    }
    .song-image {
      max-width: 100%;
      display: block;
      border-radius: 2px;
      object-fit: cover;
      object-position: center;
      width: 200px;
      height: 200px;
    }
  }
}

.channels {
  background: white;
  color: black;
  box-shadow: 0px 0px 5px rgba(166, 166, 166, 0.5);
  border-radius: 10px;
  position: absolute;
  z-index: 100;
  h3 {
    font-size: 16px;
    color: $grey;
    border-bottom: 1px solid $light-grey;
    width: 100%;
    padding: 15px 10px 2px 5px;
  }
  .premium {
    color: $salmon;
    padding-top: 0;
    margin-bottom: 2px;
    padding-bottom: 5px;
  }

  form {
    p {
      font-size: 14px;
      margin-bottom: 0;
      color: $grey;
    }

    [type="radio"]:not(:checked) + span,
    [type="radio"]:checked + span {
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      display: block;
      line-height: 1;
      height: auto;
      font-size: 1rem;
      transition: 0.28s ease;
      user-select: none;
    }

    [type="radio"] + span:before,
    [type="radio"] + span:after {
      top: 8px;
      margin: 3px;
    }

    .channel {
      margin: 5px 0;
      width: 100%;
      padding: 10px 7px;
      box-sizing: border-box;
      cursor: pointer;
      transition: all ease 0.1s;
      &:hover {
        background-color: #e6e6e6;
        background-size: 130%;
      }
      @media (max-height: 600px) {
        padding: 5px 10px;
      }
      &:last-of-type {
        margin-bottom: 10px;
      }
      .channel-name {
        padding-bottom: 5px;
        margin-bottom: 5px;
        font-size: 17px;
        border-bottom: 1px solid $light-grey;
        color: $black;
        span {
          font-weight: 600;
        }
      }
    }
  }
  .close-channel-selector {
    background: transparent;
    border: none;
    border-top: 1px solid $light-grey;
    width: 100%;
    font-size: 14px;
    text-align: center;
    padding: 5px;
    color: $grey;
    border-radius: 0 0 10px 10px;
    transition: 0.2s ease all;
    &:hover {
      color: $black;
      background: #e6e6e6;
    }
  }
}

@media screen and (max-width: $hidePlayer) {
  #player {
    box-sizing: border-box;
    width: 100%;
    left: -100%; //place off screen
    position: fixed;
    padding: 0;
    margin: 0;
    transition: left 0.5s, background 0.5s;

    &.mobile {
      left: 0; // move onscreen
      top: 0;
      overflow: hidden;
      height: 100vh;
      .bottom-bar {
        height: 0;
      }
    }

    .inner-container {
      background: #2b2a2a;
      width: 100%;
      overflow: auto;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 60px 10px 20px;
      z-index: 901;
    }

    .logo-small {
      height: 35px;
      max-width: 100%;
    }

    .close {
      display: block;
      position: absolute;
      right: 15px;
      top: 35px;
      color: white;
      border: none;
      background: #2b2a2a;
      font-size: 20px;
      line-height: 20px;
      transition: all 0.5s;
      padding: 5px;
      font-size: 1.5em;
    }
    .channel-selector {
      min-width: 30vw;
      width: 205px;
      box-sizing: border-box;
    }
    .song {
      margin: 0 auto;
      max-width: 75vw;
      box-sizing: border-box;
      .song-image {
        padding: 0;
        margin: 0 auto;
        max-width: 75vw;
        max-height: 75vw;
      }
    }
    .song-data {
      max-width: 90vw;
    }
    .full,
    .bottom-buttons {
      min-width: 30vw;
      width: 205px;
    }
    .bottom-bar {
      display: block;
      height: $bar-height;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #2b2a2a;
      transition: height 0.5s;
      overflow: hidden;
      margin: 0;

      .touch-overlay {
        width: calc(100vw - 70px);
        position: absolute;
        top: 0;
        left: 0;
        height: $bar-height;
        background: transparent;
        cursor: pointer;
      }

      .mobile-song {
        display: flex;
        .song-image {
          background-size: cover;
          height: $bar-height;
          width: $bar-height;
          object-fit: contain;
          object-position: center;
          flex: 0 0 auto;
        }
        .song-data {
          margin: 8px;
          height: 100%;
          overflow: hidden;
          position: relative;
          flex-grow: 1;
          .track-progress {
            display: none;
          }
        }
        .song-artist,
        .song-name {
          padding: 0;
          margin: 0;
          white-space: nowrap;
        }
        .controls {
          display: flex;
          margin-right: 3px;
          button {
            background: transparent;
            color: white;
            border: none;
            text-align: left;
            font-size: 1.3em;
            padding: 5px;
            margin: 0 3px;
            text-shadow: 1px 2px 2px rgb(0, 0, 0);
          }
        }
      }
    }
  }
}

// short than 700, thinner than 940
@media screen and (max-height: $shrink) and (max-width: $hidePlayer) {
  #player {
    .song {
      display: flex;
      max-width: 95vw;
      justify-content: center;
      margin-bottom: 20px;
      min-height: min-content;
      .song-image {
        max-width: 50vw;
        max-height: 50vw;
        align-self: center;
      }
      .song-data {
        max-width: 50vw;
        margin: auto 0 auto 10px;
        text-align: left;
      }
    }
    .full,
    .bottom-buttons {
      width: 90vw;
      max-width: 250px;
    }
  }
}

.playing {
  position: relative;
  margin: 0 auto;
  @keyframes sploosh {
    0% {
      box-shadow: 0 0 0 0px rgba(68, 81, 95, 0.2);
      background: rgba(68, 81, 95, 0.2);
    }
    80% {
      background: rgba(66, 166, 223, 0);
    }
    100% {
      box-shadow: 0 0 0 120px rgba(66, 166, 223, 0);
    }
  }

  @keyframes pulse {
    0% {
      -webkit-transform: scale(1);
    }
    3.3% {
      -webkit-transform: scale(1.05);
    }
    16.5% {
      -webkit-transform: scale(1);
    }
    33% {
      -webkit-transform: scale(1.05);
    }
    100% {
      -webkit-transform: scale(1);
    }
  }

  .span {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;

    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: sploosh 6s cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-iteration-count: infinite;
    -webkit-animation: sploosh 6s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-animation-iteration-count: infinite;
    z-index: -1;
  }

  .span:nth-child(2) {
    animation-delay: 0.33s;
    animation-duration: 2.2s;
    -webkit-animation-delay: 0.33s;
    -webkit-animation-duration: 2.2s;
  }

  button {
    animation: pulse 2s ease-out;
    animation-iteration-count: infinite;
    -webkit-animation: pulse 2s ease-out;
    -webkit-animation-iteration-count: infinite;
  }
}

.gear-modal {
  text-align: left;

  .quality-options-container,
  .external-player-option-container,
  .listening-options-container {
    margin-bottom: 40px;
    h2 {
      margin-bottom: 5px;
    }
    p {
      font-size: 18px;
    }
    a {
      color: $brandblue;
      text-decoration: underline;
    }
    .listen {
      color: $brandblue;
      border: 0;
      text-decoration: underline;
      padding: 0;
      font-size: 18px;
    }
  }
}

.get-premium-modal {
  li {
    font-size: 20px;
    padding: 5px 0;
  }
}

.song-image {
  /* default */
  height: 200px;
  width: 200px;
  &.jazzgroove-mix1,
  &.BLOCK {
    background-image: url("https://assets.jazzgroove.org/sonos/Mix1.jpg");
    background-size: cover;
  }
  &.jazzgroove-mix2 {
    background-image: url("https://assets.jazzgroove.org/sonos/Mix2.jpg");
    background-size: cover;
  }
  &.jazzgroove-dreams {
    background-image: url("https://assets.jazzgroove.org/sonos/Dreams.jpg");
    background-size: cover;
  }
  &.jazzgroove-gems {
    background-image: url("https://assets.jazzgroove.org/sonos/Gems.jpg");
    background-size: cover;
  }
  &.jazzgroove-smooth {
    background-image: url("https://assets.jazzgroove.org/sonos/Smooth.jpg");
    background-size: cover;
  }
  background-image: url("https://assets.jazzgroove.org/5.svg");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}

$globe-width: 4px;
$globe-height: 11px;
$globe-spacing: 16px;
$globe-spread: 3px;
$light-off-opacity: 0.4;

.lightrope {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  margin: -10px 0 0 0;
  padding: 0;
  pointer-events: none;
  width: 100%;
  li {
    position: relative;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: $globe-width;
    height: $globe-height;
    border-radius: 50%;
    margin: 4px $globe-spacing/2;
    display: inline-block;
    background: rgba(0, 247, 165, 1);
    box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread
      rgba(0, 247, 165, 1);
    animation-name: flash-1;
    animation-duration: 2s;
    &:nth-child(2n + 1) {
      background: rgba(0, 255, 255, 1);
      box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread
        rgba(0, 255, 255, 0.5);
      animation-name: flash-2;
      animation-duration: 0.4s;
    }
    &:nth-child(4n + 2) {
      background: rgba(247, 0, 148, 1);
      box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread
        rgba(247, 0, 148, 1);
      animation-name: flash-3;
      animation-duration: 1.1s;
    }
    &:nth-child(odd) {
      animation-duration: 1.8s;
    }
    &:nth-child(3n + 1) {
      animation-duration: 1.4s;
    }
    &:before {
      content: "";
      position: absolute;
      background: #222;
      width: ($globe-width - 2);
      height: $globe-height/3;
      border-radius: 3px;
      top: (0 - ($globe-height/6));
      left: 1px;
    }
    &:after {
      content: "";
      top: (0 - $globe-height/2);
      left: $globe-width - 3;
      position: absolute;
      width: 20px;
      height: ($globe-height/3 * 2);
      border-bottom: solid #222 2px;
      border-radius: 50%;
    }
    &:last-child:after {
      content: none;
    }
    // &:first-child {
    //   margin-left: -$globe-spacing;
    // }
  }
}
@keyframes flash-1 {
  0%,
  100% {
    background: rgba(0, 247, 165, 1);
    box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread
      rgba(0, 247, 165, 1);
  }
  50% {
    background: rgba(0, 247, 165, $light-off-opacity);
    box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread
      rgba(0, 247, 165, 0.2);
  }
}
@keyframes flash-2 {
  0%,
  100% {
    background: rgba(0, 255, 255, 1);
    box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread
      rgba(0, 255, 255, 1);
  }
  50% {
    background: rgba(0, 255, 255, $light-off-opacity);
    box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread
      rgba(0, 255, 255, 0.2);
  }
}
@keyframes flash-3 {
  0%,
  100% {
    background: rgba(247, 0, 148, 1);
    box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread
      rgba(247, 0, 148, 1);
  }
  50% {
    background: rgba(247, 0, 148, $light-off-opacity);
    box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread
      rgba(247, 0, 148, 0.2);
  }
}
