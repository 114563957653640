@-webkit-keyframes sk-bouncedelay {
	0%,80%,100% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1.0);
	}
}
@keyframes sk-bouncedelay {
	0%,80%,100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1.0);
		transform: scale(1.0);
	}
}
.spinner {
	margin: 0 auto;
	width: 70px;
	text-align: center;
	>div {
		width: 18px;
		height: 18px;
		background-color: white;
		border-radius: 100%;
		display: inline-block;
		-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
		animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	}
	.bounce1 {
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}
	.bounce2 {
		-webkit-animation-delay: -0.16s;
		animation-delay: -0.16s;
	}
}
