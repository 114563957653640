.swal-modal {
  border-top: 5px solid $brandblue;
  width: 95%;
  max-width: 570px;
}

.swal-overlay {
  background: rgba(0, 0, 0, 0.8);
}

.swal-title {
  color: #595959;
  background: rgb(245, 245, 245);
  font-size: 24px;
  font-weight: 200;
  text-align: left;
  padding-right: 35px; //do not overlap close button
}

.swal-title:not(:last-child) {
  padding-bottom: 10px;
}

.swal-content {
  color: #595959;
  font-size: 20px;
  font-weight: 400;
  ul {
    text-align: left;
    list-style: circle;
    margin-top: 20px;
    li {
      margin-left: 20px;
    }
  }
}

.swal-text {
  margin-top: 0;
  font-size: 20px;
  &:not(:first-child) {
    margin-top: 10px;
  }
  span {
    text-decoration: underline;
  }
}

.swal-footer {
  text-align: center;
  .swal-button {
    @include button-primary($brandblue);
    font-size: 16px;
  }
  .swal-button--learn {
    @include button-secondary($brandblue);
    font-size: 16px;
    &:hover,
    &:focus {
      background: transparent;
    }
  }
}
.swal-modal .swal2-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 1.2em;
  height: 1.2em;
  transition: color 0.1s ease-out;
  border: none;
  background: transparent;
  color: $light-grey;
  font-size: 40px;
  cursor: pointer;
  z-index: 99;
  &:hover {
    color: $salmon;
  }
  &:focus {
    outline: 0;
    color: $salmon;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -20%);
      box-shadow: 0px 0px 3px #595959;
      width: 23px;
      height: 23px;
    }
  }
}
