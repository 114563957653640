.announcement {
  position: fixed;
  top: 0;
  width: 100%;
  flex-basis: 100%;
  background: #4d535a;
  height: 35px;
  line-height: 25px;
  z-index: 9999;
  text-align: center;
  padding: 5px 0;
  color: white;
  .scroll-left {
    margin: auto;
    background: #4d535a;
    padding-bottom: 2px;
  }
  .rc-slider {
    max-width: 500px;
    margin: 0 auto 6px auto;
  }
  .rc-slider-handle {
    display: none;
  }
  .rc-slider-track {
    background: repeating-linear-gradient(
                    135deg,
                    $brandblue,
                    $brandblue 10px,
                    $brandblue 10px,
                    $brandblue 20px
    );
  }
  h1 {
    font-size: 1.1em;
    color: white;
  }
  .close {
    position: absolute;
    right: 10px;
    top: 5px;
    background: #4d535a;
    border: 0;
    color: white;
    font-size: 1.4em;
    line-height: 1em;
    padding: 0;
    border-radius: 0;
  }
}
@media (max-width: 1200px) {
  .scroll-left {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
  }
  .scroll-left h1 {
    display: inline-block;
    padding-left: 100%;
    animation: scroll-left 20s linear infinite;
  }
}
@media (max-width: 900px) {
  .scroll-left h1 {
    animation: scroll-left 17s linear infinite;
  }
}
@media (max-width: 600px) {
  .scroll-left h1 {
    animation: scroll-left 14s linear infinite;
  }
}

/* Move it (define the animation) */
@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translate(0, 0);
  }
  100% {
    -moz-transform: translate(-100%, 0);
  }
}
@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
  }
}
@keyframes scroll-left {
  0% {
    -moz-transform: translate(0, 0); /* Browser bug fix */
    -webkit-transform: translate(0, 0); /* Browser bug fix */
    transform: translate(0, 0);
  }
  100% {
    -moz-transform: translate(-100%, 0); /* Browser bug fix */
    -webkit-transform: translate(-100%, 0); /* Browser bug fix */
    transform: translate(-100%, 0);
  }
}
