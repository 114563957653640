.Toastify {
  .content {
    font-family: "HK Grotesk", sans-serif;
  font-weight: 400;
    p.update {
      color: black;
    }
    p {
      color: rgba($color: #000000, $alpha: 0.75);
      @extend .body; 
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        &.dismiss {
          @include button-tertiary($grey); 
        }
        &.refresh {
          @include button-primary($brandblue); 
          font-size: 1.1rem;
        }
      }
    }
  }
}
.app .Toastify__toast {
  padding: 15px;
  border: 1px solid $light-grey; 
  border-radius: 3px;
}
