$mobile-stack: 1050px;
$mobile-stack-min: 1051px;

.donate-main {
  background: $grey-background;
  margin-bottom: 0;
  padding-bottom: 6rem;
  min-height: 100vh;
}

.donate-main-header {
  background: $black;
  width: 100%;
  padding: 25px;
  text-align: center;
  box-shadow: none;
  position: relative;
    z-index: 1;
  img {
    width: 25%;
    max-width: 230px;
    min-width: 130px;
  }
  @media (max-width: 550px) {
    padding: 16px;
  }
}

.subheader {
  width: 100vw;
  position: relative;
  margin: 0 auto 25px;
  text-align: center;
  padding: 2rem;
  z-index: 1;
  border-radius: 3px;
  @media (min-width: $mobile-stack-min) {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -3rem;
      right: -3rem;
      left: 0;
      right: 0;
      width: 100vw;
      bottom: 50%;
      background: $black;
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      width: calc(1500px - 6rem);
      max-width: 95%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      background: white;
      z-index: -1;
      border-radius: 3px 3px 0 0;
    }
  }

  h1 {
    font-weight: 800;
  }
  h2 {
    font-size: 1.25rem;
    margin: 15px 0 5px;
    color: $black;
  }
  @media (max-width: $mobile-stack) {
    padding: 1rem;
    border-radius: 0;
    background: white;
    h1 {
      font-size: 1.7rem;
    }
    h2 {
      font-size: 1.1rem;
      margin-top: 0;
    }
  }
}

.donate-main-body {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 1500px;
  margin: 0 auto;
  > * {
    margin: 0 3rem 6rem;
  }
  > .summary {
    margin-left: 1rem;
  }
  @media (max-width: $mobile-stack) {
    flex-direction: column-reverse;
  }
  .paypal-confirmation {
    width: 100%;
    .step-box {
      width: 100%;
      margin: auto;
      .order-summary {
        text-align: center;
        .standard {
          margin: auto;
        }
        > div {
          border-radius: 5px;
          flex-basis: 50%;
          margin: 10px;
          padding: 10px;
      
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
      
        p {
          margin-bottom: 0;
        }
        .summary-overline {
          text-transform: uppercase;
          font-weight: 800;
          color: $grey;
        }
        .summary-donation {
          font-weight: 600;
          font-size: 24px;
        }
        .summary-gift {
          font-size: 20px;
        }
      }
    }
  }
}

.secondary-heading {
  font-weight: 600;
  font-size: 1.35rem;
  padding-top: 0.5em;
}

label .secondary-heading {
  text-transform: none;
}

.step-box {
  @extend .white-box;
  width: 95%;
  max-width: 1000px;
  padding: 0;


  .go-back {
    background: #e5e5e5;
    padding: 17px 20px;
    font-size: 14px;
  }

  & > *:not(header) {
    margin: 0 20px 25px;
    @media (max-width: $mobile-stack) {
      margin: 0 0 25px;
    }
  }

  .divider {
    margin-bottom: 0;
  }

  header {
    box-shadow: none;
    z-index: initial;
    display: flex;
    justify-content: flex-start;
    @media (min-width: $mobile-stack-min) {
      h2, div {
        margin-bottom: 1rem;
      }
      h2:first-of-type {
        background: $brandblue;
        padding: 20px;
        color: white;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 800;
      }
      h2:last-of-type {
        background: #e5e5e5;
        padding: 17px 20px;
        color: $black;
        font-size: 22px;
        flex-grow: 1;
      }
    }
  }

  @media (max-width: $mobile-stack) {
    box-shadow: none;
    width: 100%; 
    header {
      margin-bottom: 1rem;
    }
    h2 {
      font-weight: 600;
      font-size: 1.25rem;
    }
    h2:first-of-type {
      color: $grey; 
      margin-right: 0.4em;
    }
  }

  @media (max-width: 450px) {
    header {
      display: block;
    }
  }

  .button-primary {
    @include button-primary($accent);
    display: block;
    font-size: 1.15rem;
    letter-spacing: 0.07em;
    font-weight: 800;
    padding: 0.475rem 2.5rem;
    margin: 25px 20px 25px auto;
    @media (max-width: 750px) {
      margin: 20px auto;
      font-size: 1rem;
    }
  }
}

.radio-button-group {
  display: flex;
  justify-content: space-around;
  margin: 1.5rem auto;
}

// tabs
.radio-button-group, .plaid-container {
  [type="radio"] + span::before,
  [type="radio"] + span::after {
    top: -6px;
    left: -4px;
    width: 28px;
    height: 28px;
    color: $brandblue;
    border-color: $brandblue;
  }
  [type="radio"]:checked + span::before {
    background: $brandblue;
  }
  [type="radio"]:checked + span::after {
    content: "";
    background: url("https://assets.jazzgroove.org/checkmark.svg") 50% 60% / 70%
      no-repeat;
    transform: scale(1);
    border: 0;
  }
  [type="radio"]:not(:checked) + span::before {
    border: 2px solid lighten($black, 40%);
  }

  [type="radio"]:focus + span::before {
    box-shadow: 1px 1px 4px $brandblue, -1px 1px 4px $brandblue;
  }

  .tab-title {
    font-size: 1.35em;
    font-weight: 600;
    color: #555;
    text-transform: none;
  }

  .tab-not-selected {
    flex: 1 1 auto;
    margin: 0 10px 0 0;
    // padding: .5em; 
    .tab-title {
      color: lighten($black, 40%);
    }
  }
  .tab-selected {
    flex: 1 1 auto;
    margin: 0 10px 0 0;
    .tab-title {
      color: $brandblue;
    }
  }
  @media (max-width: 750px) {
    display: block;
    width: max-content; 
    .tab-title {
      font-size: 1.15em;
    }
    .tab-not-selected, .tab-selected {
      margin: 0 0 1.5em; 
    }
  }
}

.donate-main {
  // must next for label
  .StripeElement,
  input[type="text"],
  input[type="email"] {
    @include input-standard($checkout: true);
    margin: 3px 0;
  }
  label {
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .multiple-input-row {
    display: flex;
    justify-content: space-between;
    > * {
      flex-grow: 1;
      flex-basis: 50%;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    @media (max-width: 550px) {
      display: block;
      label:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}

.plaid-container {
  h2 {
    margin-bottom: 1rem;
  }
  label {
    font-weight: 400;
    text-transform: none;
    max-width: 95vw; 
  }
  [type="radio"]:not(:checked) + span, [type="radio"]:checked + span, span {
    font-size: 1.2rem;
  }
  .masked-number {
    padding-left: .5rem; 
    font-size: 1.2rem;
  }
}

// Hides the duplicate coupon code on desktop view.
@media (min-width: $mobile-stack-min) {
  .hide-coupon-not-mobile {
    display: none
  }
}

.breadcrumbs {
  display: flex;
  justify-content: center;
  list-style: none;
  text-align: center;
  padding: 0;
  margin-bottom: 25px;
  color: $grey;
  li {
    a {
      color: $grey;
    }
    &:last-of-type {
      font-weight: 600;
    }
    &:not(:last-of-type)::after {
      content: "»";
      padding: 0 10px;
      font-weight: 400;
    }
  }
}

.explanation {
  width: 800px;
  max-width: 95%;
  font-size: 0.9rem;
  margin: 0 auto;
}

.upscope-on-checkout {
  text-align: center;
}

.readmore {
  @include button-tertiary($brandblue);
  font-size: 16px;
}

.captcha {
  text-align: center;
}

.captcha > div > div > div {
  margin: 0 auto;
}
