.image-gallery {
  min-height: 100px;
  .image-gallery-slide {
    opacity: 0;
    transition: opacity 1000ms ease-out !important;
    transform: none !important;
    .image-gallery-image {
      max-height: 100vh;
      height: 100vh;
      background-position: 64% 0% !important;
      background-size: cover !important;
      @media (max-width: $hidePlayer) {
        // height = full height - player bar
        height: calc(100vh - #{$bar-height});
      }
      // give set height because vh is funky on iOS
      @media (max-width: 730px) and (max-height: 812px) {
        height: 750px;
      }
      @media (max-width: 600px) and (max-height: 730px) {
        height: 650px;
      }
      @media (max-width: 600px) and (max-height: 600px) {
        height: 530px;
      }
    }
  
  }
  .image-gallery-slide.center {
    opacity: 1;
  }

  .image-gallery-artist {
    bottom: 145px;
    right: 50px;
    position: absolute;
    color: #fff;
    text-shadow: 1px 1px 3px rgb(39, 39, 39);
    &.credit {
      font-size: 14px;
      font-style: italic;
      bottom: 120px;
    }
    @media (max-width: $hidePlayer) {
      right: 15px;
      top: 80px;
      left: auto;
      bottom: auto;
      padding: 0;
      margin: 0;
      &.credit {
        top: 55px;
      }
    }

    
  }

  .image-gallery-slide .image-gallery-description {
    background: linear-gradient(rgba(0, 0, 0, .01), rgba(0, 0, 0, .3) 30% ) ;
    left: 0px;
    bottom: 0;
    width: 100%;
    padding: 60px 50px 50px;
    text-align: left;
    box-sizing: border-box;
    @media (max-width: $hidePlayer) {
      padding: 60px 20px 50px;
    }
    * {
      text-shadow: 1px 1px 3px rgb(39, 39, 39);
    }
  }

  .image-gallery-heading {
    @include heading1(left, white);
    font-size: 50px;
    margin: 10px 0;
    @media (max-width: 1100px) {
      font-size: 40px;
    }
  }

  .image-gallery-channel-desc {
    @include heading2(white);
    border: none; 
    margin: 0;
  }

  .internet-radio {
    color: white;
    font-size: 18px;
    padding: 10px 0 20px;
    a {
      color: inherit;
      text-decoration: underline;
      font-weight: 700;
    }
    @media (max-width: $hidePlayer) {
      padding: 0;
    }
  }
}
