.home-content-header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #bbbdc0;
  justify-content: flex-end;
  padding: 20px 0 20px;
  .content-title {
    flex-direction: column;
    margin-top: 30px;
    @media (max-width: 500px) {
      margin-right: 10px;
    }
  }
  

  .channels {
    h3 {
      margin: 0;
    }
  }
  .channel-selector {
    font-size: 20px;
    color: $brandblue; 
    border: 2px solid $brandblue;
    border-radius: 5px;

    padding: 10px;
    margin: auto 0;

    display: flex;
    align-items: center;
    
    transition: 0.5s;
    p {
      padding-right: 10px;
    }
    &:hover {
      color: white;
      background-color: $brandblue;
    }
    @media (max-width: 450px) {
      padding: 7px;
      p {
        font-size: 12px;
      }
    }
  }
  .change-channel {
    margin: auto 0;
    color: $brandblue;
    border: 2px solid $brandblue;
    border-radius: 5px;
    transition: 0.5s;
    font-size: 20px;

    p {
      padding-right: 10px;
      @media (max-width: 450px) {
        font-size: 12px;
      }
    }
    &:hover {
      color: white;
      background-color: $brandblue;
    }
  }
}
.home {
  margin-left: 5%;
  margin-right: 5%;
  h3 {
    font-weight: 600;
    padding-right: 30px;
  }
  .now-playing {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    @media (max-width: 700px) {
      display: block;
    }
    img {
      width: 150px;
      height: 150px;
      border-radius: 2px;
      margin-left: 30px;
      margin-right: 30px;
      box-shadow: 0px 0px 5px rgba(166, 166, 166, 0.7);
      @media (max-width: 700px) {
        margin-right: 10px;
        margin-left: 10px;
      }
      @media (max-width: 450px) {
        width: 100px;
        height: 100px;
      }
    }
    .artist {
      color: $grey;
      padding-bottom: 5px;
    }
    .song-and-artist {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .artist-name {
      padding-left: 30px;
      @media (max-width: 700px) {
        padding-left: 10px;
      }
    }
    .buy-song {
      color: $brandblue;
      border: 2px solid $brandblue;
      border-radius: 5px;
      padding: 10px 15px;
      transition: 0.5s;
      font-size: 15px;
      &:hover {
        color: white;
        background-color: $brandblue;
      }
      @media (max-width: 450px) {
        padding: 5px 10px;
      }
    }
  }
  .recently-played {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 30px;
    overflow: auto;
    @media (max-width: 700px) {
      display: block;
      margin-top: 60px;
    }

    .recently-played-songs {
      display: flex;
      flex-wrap: wrap;
      height: 100% !important;
      @media (max-width: 450px) {
        padding-left: 0px;
      }
    }

    .song {
      width: 100px;
      flex-shrink: 0;
      margin-right: 20px;
      span {
        font-size: 18px;
        color: $grey;
        font-weight: 200;
      }
      img {
        width: 100px;
        height: 100px;
        border-radius: 2px;
        margin-top: 5px;
        margin-bottom: 10px;
        box-shadow: 0px 0px 5px rgba(166, 166, 166, 0.7);
        object-fit: center;
        object-position: contain;
      }
      h2 {
        margin-bottom: 5px;
        font-size: 15px;
        color: $grey;

        font-weight: 200;
      }
      h3 {
        margin-bottom: 10px;
      }
      .buy-song {
        color: $brandblue;
        border: 2px solid $brandblue;
        border-radius: 5px;
        padding: 10px 15px;
        transition: 0.5s;
        font-size: 15px;
        &:hover {
          color: white;
          background-color: $brandblue;
        }
        @media (max-width: 1200px) {
          margin-bottom: 20px;
        }
        @media (max-width: 450px) {
          padding: 5px 10px;
        }
      }
    }
  }
}