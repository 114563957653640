.change-password-form {
  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
  }
  input {
    width: 55%;
  }
  .password-field {
    width: 55%;
    input {
      width: 100%;
      margin-bottom: 0;
    }
    button {
      top: 62%; 
    }
  }
}

.password-requirements {
  p {
    text-align: center;
    font-size: 1em;
    line-height: 1.3em;
  }
  li {
    font-size: 0.8em;
    line-height: 1.3em;
  }
}

// NEW ACCOUNT PAGE STYLING

.page.account {
  // general page styles
  background: $grey-background;
  margin-bottom: 0;

  p,
  h1,
  h2,
  h3 {
    margin-bottom: 0;
  }

  .primary-button {
    @include button-primary($brandblue);
  }

  .secondary-button {
    @include button-secondary($grey);
    text-align: center;
  }

  .tertiary-button {
    @include button-tertiary($grey);
    margin-left: auto;
  }

  .account-tabs {
    display: flex;
    justify-content: center;
    list-style: none;
    width: 100%;
    background: white;
    box-shadow: 0 1px 4px solid $light-grey;
    border-bottom: 2px solid #eee;
    border-radius: 5px;
    margin-top: 20px;
    padding: 0;
    text-align: center;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
    li {
      a {
        display: block;
        padding: 10px 15px;
        font-size: 20px;
        text-decoration: none;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 4px;
          background: currentColor;
          width: 100%;
          opacity: 0;
        }
        &:hover,
        &:focus,
        &.active {
          background: lighten($brandblue, 50%);
          outline: 0;
          &::after {
            opacity: 1;
          }
        }
      }
      a.active {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  .contained-content {
    max-width: 700px;
  }

  // MY CURRENT PLAN

  .plan {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    overflow: visible;
    > div {
      flex-grow: 5;
      padding: 0 15px;
    }

    .overline {
      color: $grey;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 600;
    }

    h2 {
      font-size: 24px;
      font-weight: 600;
    }

    .free-trial-indicator {
      color: $salmon; 
      margin-top: 10px;
    }

    .logo {
      background: #2b2a2a;
      padding: 10px;
      border-radius: 100%;
      max-width: 75px;
      height: auto;
    }
  }

  .reload {
    background: none;
    border: none;
    color: $brandblue;
    font-size: 1.5em;
    &.info-container {
      .fas {
        color: inherit;
        font-size: inherit;
      }
      .tooltiptext {
        left: -100%;
        &::after {
          left: auto;
        }
      }
    }
    &:disabled {
      animation: spin 1s linear infinite;
    }
    &:focus {
      outline: none;
    }
    @keyframes spin {
      0% {
        transform: rotateZ(0);
      }
      100% {
        transform: rotateZ(360deg);
      }
    }
  }

  .plan-explanation {
    border-top: 1px solid $light-grey;
    margin-top: 0;
  }

  .renewal {
    // "Switch to $10/month" area
    border-top: 1px solid $light-grey;
    margin-top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > a {
      margin-right: 15px;
    }
    @media (max-width: 520px) {
      display: block; 
      > a {
        margin: 0 0 10px 0;
        display: block;
        text-align: center; 
      }
    }
  }

  .pre-promo {
    h2 {
      font-weight: 600;
    }
  }

  .promo {
    text-align: center;
    h2 {
      font-weight: 600;
    }
    h3 {
      font-weight: 800;
      font-size: 1em;
      text-transform: uppercase;
      letter-spacing: 0.03em;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      max-width: 550px;
      margin: 0 auto 20px;
      @media (max-width: 650px) {
        padding: 0;
      }
      @media (max-width: 580px) {
        display: block;
        
      }
    }
    li {
      flex-basis: 50%;
      text-align: left;
      padding: 5px;
      list-style: none;
      font-size: 1.2em;
    }

    .upgrade {
      @include button-primary(#ffdf2c, #333333);
      font-size: 1.2em;
      &:hover {
        background: #ffd82d;
        border-color: #ffd82d;
      }
    }

    .tertiary-button {
      @include button-tertiary(darken($accent, 10%));
      display: block;
      margin: 5px auto 0;
    }
  }

  .premium-plus-cta {
    @include button-secondary(darken($accent, 10%));
    margin-top: 10px;
  }

  .premium-plus .tertiary-button {
    margin-left: auto;
  }

  // AUDIO SETTINGS
  .audio-settings {
    h2 {
      font-size: 26px;
      font-weight: 600;
    }
    .setting-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.1rem;
      padding-bottom: 15px;
      &:not(:last-of-type) {
        border-bottom: 1px solid $light-grey;
        margin-bottom: 15px;
      }
      div:first-of-type {
        display: flex;
        margin-right: auto;
        @media (max-width: 630px) {
          min-width: 50%; 
          padding-right: 5px;
        }
      }
      .not-available {
        font-style: italic;
        margin-right: 20px;
        font-size: 0.9rem;
      }
    }
  }

  $width: 100px;
  $height: 30px;
  $padding: 5px;
  $border-radius: 25px;
  $toggle-color: white;

  .enabled-badge {
    width: $width;
    height: $height;
    border-radius: $border-radius;

    background: $accent;
    color: $toggle-color;
    font-style: initial;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 6px;
  }

  .dropdown {
    form {
      width: 100%;
    }
    .audio-quality {
      margin-right: 10px;
    }
    .streaming-format {
      margin-left: 10px;
    }
    select {
      background: url(../../Assets/dropdown-arrows.svg) no-repeat;
      background-position: right 3% bottom 50%;
      background-size: 30px 30px;
      background-color:#ececec;
      border: 2px;
      outline: 2px;
      float: left;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }

  .premium-url {
    flex: 1;
    position: relative;
    button {
      @include button-primary($brandblue);
      position: absolute;
      display: block;
      border-width: 2px;
      font-size: .8em;
      letter-spacing: 0.07em;
      font-weight: 800;
      padding: 0.3rem .6rem;
      right: 3px;
      top: 2px;
    }
  }

  .flac-support{
    font-size: 1em;
    color: red;
    padding: 0px 0;
    margin-bottom: 5px;
  }

  span.switcher {
    position: relative;
    width: $width;
    height: $height;
    border-radius: 2em;
    cursor: pointer;
    input {
      appearance: none;
      position: relative;
      width: $width;
      height: $height;
      border-radius: $border-radius;
      outline: none;
      font-weight: 600;
      font-size: 0.8rem;
      transition: 0.25s -0.1s;
      cursor: pointer;
      &:before,
      &:after {
        z-index: 2;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: $toggle-color;
      }
      &:before {
        content: "ENABLED";
        left: 8px;
      }
      &:after {
        content: "DISABLED";
        right: 8px;
      }

      &:checked {
        background-color: $accent;
        &:before {
          transition: opacity 0.5s 0.2s;
          opacity: 1;
        }
        &:after {
          transition: opacity 0.5s;
          opacity: 0;
        }
        & + label {
          right: $padding;
          left: calc(100% - 25px);
          transition: right 0.5s, left 0.4s 0.2s;
        }
      }
      &:not(:checked) {
        background: $salmon;
        transition: background 0.5s -0.1s;
        &:before {
          opacity: 0;
          transition: opacity 0.5s;
        }
        &:after {
          opacity: 1;
          transition: opacity 0.5s 0.2s;
        }
        & + label {
          left: $padding;
          right: calc(100% - 25px);
          transition: left 0.5s, right 0.4s 0.2s, background 0.35s -0.1s;
        }
      }
      &:disabled {
        background: $light-grey;
        &:before {
          opacity: 0;
          transition: opacity 0.5s;
        }
        &:after {
          opacity: 1;
          transition: opacity 0.5s 0.2s;
          content: "";
        }
        & + label {
          left: $padding;
          right: calc(100% - 25px);
          transition: left 0.5s, right 0.4s 0.2s, background 0.35s -0.1s;
        }
      }
      &:focus {
        box-shadow: 0px 0px 6px $brandblue;
      }
    }
    //indicator
    label {
      z-index: 1;
      position: absolute;
      top: $padding;
      bottom: $padding;
      background: white;
      border-radius: 20px;
      margin: 0;
    }
  }

  // ACCOUNT SETTINGS
  .account-settings {
    @extend .white-box;
    max-width: 500px;

    .name-fields {
      display: flex;
      justify-content: space-between;
      label {
        flex-grow: 1;
        &:first-of-type {
          padding-right: 15px;
        }
      }
    }

    .field {
      width: 100%;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 20px;
      input {
        width: 100%;
        margin: 3px 0;
      }
    }

    .account-password-field {
      display: block;
    }

    .change-password {
      @include button-tertiary($brandblue);
      display: block;
    }

    .update-account {
      margin: 25px auto;
    }

    .logout-account {
      @include button-secondary($grey);
    }
  }

  // DONATION HISTORY

  .donation-history {
    h2 {
      font-size: 26px;
      font-weight: 600;
    }
  }

  .donation-history-item {
    margin: 15px 0;
    padding: 5px 0;
    &:not(:last-of-type) {
      border-bottom: 1px solid $light-grey;
      padding-bottom: 15px;
    }
    h3 {
      font-size: 1.25em;
      font-weight: 600;
      margin: 3px 0;
    }
    .heading-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 8px;
    }
    ul {
      padding-left: 0;
    }
    li {
      margin-bottom: 8px;
      list-style: none;
      padding: 0;
    }
    .italic {
      font-style: italic;
    }
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .modify-button {
      @include button-secondary($brandblue);
      z-index: 10;
      &.active {
        color: darken($brandblue, 15%);
        outline: none;
        border-color: transparent;
        &:hover {
          box-shadow: none;
        }
      }
    }
    .tertiary-button {
      margin-left: auto;
    }
  }

  .update-donation-container {
    margin-top: 5px;
    background: #e7e7e7;
    padding: 15px 5px;
    position: relative;
    border-radius: 3px;
    form {
      display: flex;
      align-items: center;
    }

    &::before {
      content: "";
      position: absolute;
      background: #e7e7e7;
      border: 1px solid #e7e7e7;
      height: 122px;
      width: 270px;
      top: -48px;
      left: -5px;
      border-radius: 3px;
    }
    .primary-button {
      z-index: 10;
    }
  }

  .currency-wrapper {
    width: 152px;
    margin: 0 15px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 1px solid #797979;
    padding: 0;
    border-radius: 30px;
    background: #f7f7f7;
    position: relative;
    &:hover {
      border: 1px solid #d2d2d2;
      box-shadow: inset 0 0 0 1px $light-grey;
      border-color: $light-grey;
    }
    @media (max-width: 450px) {
      margin: 15px auto 0;
      width: 90%;
    }
    input {
      border: none;
      color: #000000;
      background: transparent;
      font-size: 1.25em;
      padding: 6px;
      display: block;
      text-align: center;
      margin: 0;
      width: 150px;

      &:focus,
      &:active {
        background: #f7f7f7;
      }
    }

    .currency-sign {
      font-size: 1.25em;
      color: #000000;
      padding: 5px 0;
      position: absolute;
      left: 10px;
      margin: 0;
    }
    .currency {
      font-size: 1.25em;
      outline: none;
    }
  }
}

.badge {
  padding: 1px 10px;
  border-radius: 2em;
  margin-left: 5px;
  margin-right: auto;
  margin-bottom: 3px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  font-weight: 600;
  &.active {
    background: $accent;
  }
  &.cancelled {
    color: white;
    background: red;
  }
  &.expiring {
    color: white;
    background: red;
  }
}
