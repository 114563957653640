@media (min-width: $mobile-stack-min) {
  .summary {
    flex-basis: 600px;
  }
}
.summary {
  .chosen-plan {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0.5em;
  }
  .chosen-price {
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: .5em;
  }
  .gift-text {
    color: $brandblue;
    text-align: center;
  }
  .chosen-gift {
    display: flex;
    align-items: center;
  }
  .gift-image {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-right: 1em;
    &.default {
      background: $black;
      border-radius: 50%;
      padding: 5px;
    }
  }
  .gift-title {
    font-size: 1.25rem;
    margin: 0;
  }
  .summary-divider {
    width: 110%;
    margin-left: -5%;
    margin-bottom: 2rem;
    margin-top: 2rem;
    border-color: rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 600px) {
    .gift-image {
      width: 70px;
      height: 70px;
    }
    .gift-title {
      font-size: 1rem;
    }
    .summary-divider {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  }
  @media (min-width: $mobile-stack-min) {
    .summary-toggle,
    .summary-close {
      display: none;
    }
  }
}

.gift-placeholder {
  width: 120px;
  height: 120px;
  margin-right: 15px;
  background: $black;
  padding: 7px;
  border-radius: 50%;
  @media (max-width: 530px) {
    display: none;
  }
}

.coupon {
  margin-bottom: 10px;
  .flex {
    display: flex;
  }
  button {
    border-radius: 0 3px 3px 0;
    padding: 0.5em 1em;
  }
  input {
    margin: 0;
    border-radius: 3px 0 0 3px;
    border: 1px solid $grey;
    flex-grow: 1;
    min-width: 0; // inputs have a default min-width that prevent standard flex behavior
    padding: 5px;
  }
  label {
    margin-bottom: 3px;
  }
  .fas {
    color: $accent;
  }
  p {
    margin-bottom: 0;
  }
  .error {
    color: $salmon;
    margin-top: 3px;
  }
  .warning {
    color: $brandblue;
    margin-top: 15px;
  }
  &.successful {
    max-width: initial;
  }
  .remove-coupon {
    @include button-tertiary($grey);
    font-size: .75em;
    .fas {
      color: $grey;
    }
  }
}

// .show-coupon {
//   @include button-tertiary($grey);
//   margin: 0 20px 10px;
// }

.subtotal-container {
  padding: 0;
  margin: 0;
  li {
    display: flex;
    justify-content: space-between;
    &.total {
      font-size: 1.25em;
      margin: 1em 0;
    }
  }
}

.benefits-container {
  display: flex;
  align-items: flex-start;
  @media (max-width: 1200px) {
    display: block;
  }
}

.included-benefits {
  ul {
    list-style: none;
    padding-left: 0;
    margin-left: 10px;
  }
  p {
    text-transform: uppercase;
    font-weight: 600;
    color: darken($accent, 30%);
    margin-bottom: 0.5rem;
  }
  .fas {
    color: $accent;
  }
}

.upsell {
  @extend .green-box;
  margin: 0;
  text-align: center;
  h3 {
    font-size: 20px;
    line-height: 1.6em;
  }
  .fas {
    display: block;
    margin-bottom: 10px;
    font-size: 1.6em;
  }
  &.to-premium {
    ul {
      padding: 0;
      list-style: none;
    }
    li {
      margin-bottom: 6px;
      line-height: initial;
    }
  }
  @media (max-width: 1200px) {
    margin: 20px 0 0;
    max-width: 620px;
  }
}

@media (max-width: $mobile-stack) {
  .breadcrumbs {
    display: none;
  }
  .donate-main {
    background: white;
  }
  .subheader {
    margin-bottom: 0;
  }
  .donate-main-body > *,
  .donate-main-body .summary {
    padding: 0 1rem;
    max-width: 100vw;
    margin: 0;
    margin-bottom: 2rem;
  }
  .summary-toggle {
    width: 100vw;
    margin-left: -1rem;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    background: $grey-background;
    border: solid rgba(0, 0, 0, 0.1);
    border-width: 1px 0 0 0;
    color: $grey;
    position: relative;
    z-index: 1;
    .summary-open {
      i {
        transform: rotateX(180deg);
        transition: 0.2s ease all;
      }
    }
    .summary-closed {
      i {
        transition: 0.2s ease all;
      }
    }
  }
  .summary-close {
    margin-left: 0 auto;
    padding: 0.5rem 1rem;
    color: $grey;
    display: block;
  }
  .summary {
    background: $grey-background;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
    .chosen-price {
      font-size: 1.1rem;
    }
    .chosen-gift {
      justify-content: center;
      .gift-placeholder {
        display: initial;
        width: 80px;
        height: 80px;
      }
    }
  }
  .sidebar-content {
    margin: 0 auto;
    padding: 1rem 1rem 0 1rem;
    position: absolute;
    overflow: hidden;
    background: #dddddd;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    left: 0;
    right: 0;
    transition: 1s ease-in-out all;
    &--is-collapsed {
      visibility: hidden;
      transform: translateY(-100%);
    }
    &--is-expanded {
      padding-top: 1rem;
      transform: translate(0, 0);
      margin-top: 42px;
    }
    > *:not(.summary-divider) {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .subtotal-container {
    .summary-divider {
      display: none;
    }
  }
  .upsell h3 {
    font-size: 1rem;
  }
}
