.premium {
  h2 {
    font-size: 30px;
    margin: 20px 0 20px 0;
    line-height: 1.3em;
    text-align: center;
    @media (max-width: 700px) {
      font-size: 25px;
    }
  }
  h3 {
    margin-left: 5%;
    text-align: center;
    font-size: 20px;
  }
  .icons {
    display: flex;
    width: 90%;
    margin: 0 auto 50px;
    justify-content: space-around;
    max-width: 1500px;
    @media screen and (max-width: 800px) {
      flex-wrap: wrap;
      margin: 0 auto;
    }
    @media screen and (max-width: 400px) {
      display: block;
    }
    & > div {
      width: 20%;
      text-align: center;
      @media screen and (max-width: 800px) {
        width: 45%;
        margin: 20px auto;
      }
      @media screen and (max-width: 400px) {
        width: 90%;
        img {
          max-width: 200px;
        }
      }
      h2 {
        margin: 10px 0;
        font-size: 24px;
        font-weight: 400;
      }
      p {
        font-size: 18px;
        line-height: 1.3;
        @media (max-width: 500px) {
          font-size: 16px;
        }
      }
    }
  }
  p.tiny {
    padding: 0 10% 7px;
    @media screen and (max-width: 750px) {
      padding-top: 5px;
    }
  }
}



.swal-select {
  width: 150px;
  margin: 0 auto 10px;
  &.select-gift {
    width: auto;
  }
}
.swal-input {
  @include button-primary($brandblue);
  padding: 6px 10px;
  font-size: 16px;
}
