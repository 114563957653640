// COLORS

// old colors, keeping for now incase we need to revert
// $brandblue: #65a6e8;
// $accent: #7BAB7D;

$brandblue: #276db2;
$lightblue: #adcff2;
$accent: #008752;
$salmon: #e36a62;
$salmon: #c73a3c;
$grey-background: #f5f5f5;
$light-grey: #cccccc;
$grey: #737373;
$black: #1e1e1e;

// Page variables
$hidePlayer: 940px;
$column: 1050px;
$donations-border: 1px solid $light-grey;

// PAGE CONTAINER

.flex-container {
  display: flex;
  position: relative;
  min-height: 100vh;
  &.announcement-shown {
    main,
    #player .inner-container {
      margin-top: 35px;
    }
    #player .inner-container {
      height: calc(100vh - 35px);
    }
  }
  @media (min-width: 900px) {
    &.fd-enabled {
      main,
      #player .inner-container {
        margin-top: 72px;
      }
      #player .inner-container {
        height: calc(100vh - 35px);
      }
    }
  }
  @media (max-width: $hidePlayer) {
    &.fd-enabled {
      #player .inner-container {
        margin-top: 0;
      }
    }
  }
}

main {
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

/* 
TYPOGRAPHY
*/

body {
  font-family: "HK Grotesk", sans-serif;
  font-weight: 400;
  max-width: 100vw;
  overflow-x: hidden;
  color: $black;
  font-size: 16px;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "HK Grotesk", sans-serif;
  font-weight: 200;
}

@mixin body-copy($color: $black) {
  font-size: 1.25em;
  font-weight: 400;
  color: $color;
  line-height: 1.5;
  margin-bottom: 1.25em;
  @media (max-width: 800px) {
    font-size: 1em;
  }
}

p.body {
  @include body-copy;
  a {
    color: $brandblue;
  }
}

a {
  color: $brandblue;
  &:hover,
  &:focus {
    color: darken($brandblue, 15%);
  }
}

@mixin heading1($align: center, $color: #{$black}) {
  text-align: $align;
  font-weight: 600;
  color: $color;
}

@mixin heading2($color: #{$black}) {
  font-size: 1.875em;
  margin: 30px 0 20px 0;
  font-weight: 200;
  color: $color;
  border-bottom: 2px solid $light-grey;
  padding-bottom: 5px;
}

@mixin heading3($color: #{$black}) {
  font-size: 1.25em;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 5px;
  color: $color;
}

@mixin heading4($color: #{$black}) {
  font-size: 1.1;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 5px;
  color: $color;
}

// ELEMENTS

@mixin button-primary($background: $brandblue, $color: white) {
  font-family: "HK Grotesk", sans-serif;
  border-radius: 2em;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.04em;
  font-size: 0.95em;
  transition: all 0.2s ease;
  border-style: solid;
  border-width: 2px;
  padding: 0.5em 1em;
  box-sizing: border-box;
  text-decoration: none;

  color: $color;
  background: $background;
  border-color: $background;

  &:hover,
  &:focus {
    box-shadow: 1px 1px 4px rgba(34, 34, 34, 0.1),
      -1px 1px 4px rgba(34, 34, 34, 0.1);
    text-decoration: none;
    background: darken($background, 15%);
    border-color: darken($background, 15%);
    color: $color;
    outline: 0;
  }
  &:focus {
    box-shadow: 1px 1px 4px $brandblue, -1px 1px 4px $brandblue;
  }
}

@mixin button-secondary($color: $accent, $background: transparent) {
  font-family: "HK Grotesk", sans-serif;
  border-radius: 2em;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.04em;
  font-size: 0.95em;
  transition: all 0.2s ease;
  border-style: solid;
  border-width: 2px;
  padding: 0.5em 1em;
  box-sizing: border-box;
  text-decoration: none;

  color: $color;
  border-color: currentColor;
  background: $background;

  &:hover,
  &:focus {
    box-shadow: 1px 1px 4px rgba(34, 34, 34, 0.1),
      -1px 1px 4px rgba(34, 34, 34, 0.1);
    text-decoration: none;
    color: darken($color, 15%);
    outline: none;
  }
  &:focus {
    box-shadow: 1px 1px 4px $brandblue, -1px 1px 4px $brandblue;
  }
}

@mixin button-tertiary($color: $grey) {
  color: $color;
  font-weight: 800;
  border: none;
  background: transparent;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 0;
  border: 2px solid transparent;
  transition: 0.1s ease-in all;
  position: relative;
  font-size: 0.95em;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: currentColor;
    width: 100%;
    opacity: 0;
  }
  &:hover,
  &:focus {
    outline: 0;
    text-decoration: none;
    outline: none;
    &::after {
      opacity: 1;
    }
  }
}

button.standard {
  @include button-primary;
}

@mixin input-standard($checkout: false) {
  background: white;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  width: 100%;
  display: block;
  font-size: 18px;
  margin: 10px 0px;
  &:focus {
    outline: none;
  }
  &:disabled {
    border-color: $grey
  }

  @if $checkout {
    border-radius: 3px;
    background: $grey-background;
    border-color: $light-grey;
    padding: 5px 10px;
    &:focus {
      outline: none;
      border-color: $brandblue;
    }
  } @else {
    border-radius: 2em;
    padding: 5px 15px;
    border-color: $brandblue;
    &:focus {
      border-color: $accent;
      background: #eeeeee;
    }
  }
}

input[type="text"],
input[type="date"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="url"] {
  @include input-standard();
}

select {
  font-size: 18px;
  padding: 8px 15px;
  width: 100%;
  max-width: 100%;
  box-shadow: 0;
  border-radius: 2em;
  border: 0; 
  background: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  &:focus {
    outline: 0;
    background: #eeeeee;
  }

  &::-ms-expand {
    display: none;
  }
}

.select-wrapper {
  display: block;
  font-size: 18px;
  color: #444;
  line-height: 1.3;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 3px 0;
  border: 1px solid $brandblue;
  box-shadow: 0;
  border-radius: 2em;
  position: relative;
  &::after {
    color: $brandblue;
    font-size: 2rem;
    line-height: 1em;

    position: absolute;
    top: -2px;
    right: 1rem;

    display: inline-block;
    pointer-events: none;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    content: "\f0dd";
  }
}

input,
textarea,
select {
  font-family: "HK Grotesk", sans-serif;
}

a,
input {
  transition: all 0.3s ease;
}

.checkbox {
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }

  label {
    position: relative;
    display: inline-block;
    padding-left: 25px;
    font-size: 18px;
    text-transform: none;
    font-weight: 200;
    &::before,
    &::after {
      position: absolute;
      content: "";
      display: inline-block;
    }
    &::before {
      height: 16px;
      width: 16px;
      border: 1px solid $brandblue;
      left: 0px;
      top: 3px;
    }
    &::after {
      height: 5px;
      width: 9px;
      border-left: 2px solid $brandblue;
      border-bottom: 2px solid $brandblue;
      transform: rotate(-45deg);
      left: 4px;
      top: 7px;
    }
    span {
      display: block;
    }
  }

  input[type="checkbox"] + label::after {
    content: none;
  }
  input[type="checkbox"]:checked + label::after {
    content: "";
  }
  input[type="checkbox"]:focus + label::before {
    outline: $brandblue auto 5px;
  }
}

.hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.buy-song {
  display: none !important;
}

.channel-selector {
  cursor: pointer;
  background: transparent;
  p {
    margin-bottom: 0;
  }
}

.hideAnimation {
  animation: none !important;
}

.chatlio-widget {
  @media (max-width: $hidePlayer) {
    display: none;
  }
}

// scroll down / more arrow animated on home screen slider
.top-panel {
  position: relative;
}
.top-panel::before {
  color: #fff;
  opacity: 0.8;
  text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  font-size: 2rem;
  text-align: center;

  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateY(-50%);

  animation: bounce 3s ease-in-out infinite;
  z-index: 10000;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f078";

  @media screen and (max-width: $hidePlayer) {
    bottom: 4rem;
    left: auto;
    right: 2rem;
    transform: none;
  }

  @media (max-width: 550px) {
    display: none;
  }
}

@keyframes bounce {
  // 0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  // 40% {transform: translateY(-25px);}
  // 60% {transform: translateY(-15px);}

  0% {
    color: rgba(255, 255, 255, 0.8);
  }
  50% {
    color: rgba(255, 255, 255, 0.5);
  }
  100% {
    color: rgba(255, 255, 255, 0.8);
  }
}

.tiny {
  font-size: 14px;
}

.center-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.white-box,
.green-box {
  padding: 20px 25px;
  margin-top: 40px;
  box-shadow: 1px 3px 5px $light-grey;
  border-radius: 5px;
  overflow: hidden;
  .divider {
    width: calc(100% + 50px);
    position: relative;
    left: -25px;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-color: rgba(0, 0, 0, 0.1);
  }
}

.white-box {
  background: white;
}

.green-box {
  background: #bad3bb;
  color: #202920;
  .body,
  .tiny {
    color: inherit;
  }
}

.zoom {
  cursor: zoom-in;
}
