.payment-methods-container {
  margin-bottom: 3rem;
  text-align: center;
  @media (max-width: 750px) {
    text-align: left;
  }
}

.payment-type-image {
  height: 25px;
  display: block;
  margin: 12px auto 0;
  @media (max-width: 750px) {
    display: inline-block;
    margin: 0 15px;
    vertical-align: bottom;
  }
}

.credit-card-container,
.paypal-container,
.plaid-container {
  margin: 2rem 0;
}

.paypal-button {
  margin: 20px auto 20px;
  display: block;
  padding: 0.6rem 1.2rem;
  border: 0;
  background: #fec349;
  border-radius: 2em;
  font-weight: 600;
  img {
    vertical-align: top;
    height: 23px;
    padding-left: 8px;
  }
  &:hover,
  &:focus {
    background: #f1b945;
  }
}
.paypal-loading-message {
  text-align: center;
  .spinner > div {
    background-color: $brandblue;
  }
}

.paypal_gift-image {
  width: 200px;
  height: auto;
  margin: 0 auto;
}

.plaid-container button {
  @include button-secondary($brandblue);
  margin: 0 auto;
  display: block;
}

.auto-renew, .add-fees {
  label {
    text-transform: none;
    font-weight: 400;
  }
}