.blog {
  .blog-posts-grid {
    max-width: 1335px;
    margin: 0 auto;
    padding: 0 20px;
    @media (max-width: 850px) {
      width: 95%;
    }
  }

  .grid-item-content {
    padding: 10px;
  }

  .grid-image-top {
    height: 45%;
    width: 120%;
    background-size: cover;
    position: relative;
    background-position: 50% 50%;
    left: -10.5%;
    top: -4.5%;
  }

  .grid-image-top .centered {
    text-align: center;
    transform: translate(-50%, -50%);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 54.5%;
    left: 50%;
    width: 60%;
    height: 60%;
    background-position: center;
  }

  .item-img-wrapper {
    @media (min-width: 600px) {
      height: 280px;
    }
    @media (max-width: 599px) {
      height: 200px;
    }
    cursor: pointer;
    margin-bottom: 10px;
    .item-img {
      max-height: 100%;
      max-width: 100%;
      margin: 0 auto;
      display: block;
    }
  }

  .item-title {
    font-size: 24px;
    line-height: 1.3;
    font-weight: 600;
    margin-bottom: 18px;
    display: block;
    color: $black;
  }

  .item-created {
    text-transform: uppercase;
    display: block;
    margin-bottom: 20px;
    font-size: 0.875em;
  }

  .item-excerpt {
    margin-bottom: 20px;
    display: block;
    font-size: 0.875em;
  }

  .more-info {
    margin-bottom: 25px;
    padding-left: 0;
    transition-duration: 0.5s;
    font-size: 1em;
    display: flex;
    color: black;
  }

  .more-info i {
    padding-left: 5px;
    transition-duration: 0.5s;
    position: relative;
    top: 5px;
  }

  .grid-item:hover {
    border-color: $brandblue;
    transition: 0.5s ease all;
  }

  .grid-item:hover .more-info i {
    padding-left: 20px;
    transition-duration: 0.5s;
  }

  .more-info i::before {
    font-size: 1em;
  }

  @media (max-width: 815px) {
    .grid-item {
      flex-basis: 47%;
    }
  }

  @media (max-width: 555px) {
    .grid-item {
      flex-basis: 100%;
    }
  }
}
