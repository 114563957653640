.contact {
  .contact-parent {
    @media (min-width: 800px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
      justify-items: stretch;

      max-width: 1000px;
      margin: 0 auto;
      padding: 0 20px;
    }

    @media (max-width: 800px) {
      display: block;
    }
  }
  .contact-item {
    @extend .white-box;
    padding: 0;
    margin: 0;
    @media (max-width: 800px) {
      margin: 30px;
    }
    @media (max-width: 650px) {
      margin: 25px 15px;
    }
    h2 {
      background: #e5e5e5;
      padding: 15px;
      margin-left: 70px;
      margin-top: 0;
      border: none;
      position: relative;
      &::before {
        width: 70px;
        left: -70px;
        top: 0;
        padding: inherit;
        text-align: center;
        position: absolute;
        color: white;
        background: $brandblue;

        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        @media (max-width: 800px) {
          width: 50px;
          left: -50px;
        }
      }
      @media (max-width: 800px) {
        font-size: 1.3rem;
        padding: 10px;
        margin-left: 50px;
      }
    }
    &.phone h2::before {
      content: "\f095";
      transform: scaleX(-1); //flip horizontal
    }
    &.email h2::before {
      content: "\f1fa";
    }
    &.address h2::before {
      content: "\f0e0";
    }
    &.chat h2::before {
      content: "\f086";
    }
    p {
      padding: 0 20px;
      font-size: 1.1rem;
      overflow-wrap: break-word;
      .bold {
        font-weight: 600;
      }
    }
    .contact-method {
      font-size: 1.3em;
      font-weight: 600;
      display: block;
      @media (max-width: 440px) {
        font-size: 1.15em;
      }
    }
    a.contact-method {
      color: $brandblue;
    }
  }
}
